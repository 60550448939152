.photo {
    display: inline-flex;
}

.homeButton {

}

.gridBackground {
    width: 200vw;
    height: 200vh;
    background: white;
    background-image: radial-gradient(black 1px, transparent 0);
    background-size: 32px 32px;
    background-position: -8px -8px;
    position: absolute;
    pointer-events: none;
}

button.homeButton {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
}

.rotatingTextRegistryCard {
    transform-origin: 85px 115px;
    animation: rotateText 10s infinite;
    animation-timing-function: linear;
}

.rotatingTextRegistryCard:hover {
    transform: rotate(360deg);
}

@keyframes rotateText {
    0% {
        transform: rotate(360deg);
    }

    /* 50% {
        transform: rotate(180deg);
    } */

    100% {
        transform: rotate(0deg);
    }
}