.shortcut {
    width: 64px;
    height: 64px;
    background-color: #fff;

    border: 1px black dashed;
}

.highlighted {
    border: 1px blue solid;
}