.contents
{
    width: 512px;
    max-width: 70vw;
    padding: 8px;
}

.contentImg
{

    max-width: 65vw;
    max-height: 50vh;
}

.linkList
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 8px;
}