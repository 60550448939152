.stickyNote {
    position: absolute;
    /* width: 232px;
    height: 232px; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    /* overflow: hidden; */
    z-index: 10;
    resize: both;
    overflow: auto;
    min-width: 100px;
    min-height: 100px;
    background-color: white;
    border: 1px black solid;
}

.stickyNoteContents {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
}

.stickyNoteTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
}

.titleIcon {
    width: 16px;
    height: 16px;
}

.closeButton {
    border: 0px solid black;
    background-color: transparent;
    color: black;
    width: 16px;
    height: 16px;
    font-size: 12px;
    cursor: pointer;
}


button.stickyNote {
    border: none;
    outline: none;
    cursor: pointer;
}

/* a.stickyNote {
    border: none;
    outline: none;
    cursor: pointer;
} */

.grabbed {
    cursor: grabbing;
}

.shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.long {
    width: 594px;
    height: 92px;
}

.mid {
    width: 344px;
}

.short {
    height: 92px;
}

.small {
    width: 127px;
    height: 127px;
}


.extraSmall {
    width: 95px;
    height: 95px;
}

.circle {
    border-radius: 100%;
}

.red {
    background-color: #FFD2D2;
}

.pink {
    background-color: #FFE4F4;
}

.yellow {
    background-color: #FFFEE1;
}

.blue {
    background-color: #22A4FB;
}

.light-blue {
    background-color: #D2F4FF;
}

.purple {
    background-color: #E2E1FF;
}

.green {
    background-color: #E8FFD6;
}

.green2 {
    background-color: #E3FFBF;
}


.noteText {
    margin: 15px;
    display: flex;
    flex-direction: column;
    /* height: 100%;
    width: 100%; */
}

.noteTitle {
    font-weight: bold;
    font-style: italic;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.normalWeight {
    font-weight: normal;
}

.centerH {
    align-items: center;
}

.centerV {
    justify-content: center;
}

.left {
    align-items: flex-start;
}

.right {
    align-items: flex-end;
}

.top {
    justify-content: flex-start;
}

.bottom {
    justify-content: flex-end;
}

.noteList {
    padding-inline-start: 20px;
    margin: 0;
}

input {
    border: none;
    outline: none;
    text-align: center;
    background-color: transparent;
    border-bottom: 1px solid black;
}

.dynamicHeight {
    height: unset;
}

.newYellow {
    background-color: #FFFF5C;
}

.newRed {
    background-color: #D60D0D;
    color: white;
}

.newLightBlue {
    background-color: #CAE8FF;
}

.newBlue {
    background-color: #006FC3;
    color: white;
}

.newGreen {
    background-color: #004225;
    color: white;
}

.newGrey {
    background-color: #EFEFED;
}

.marginTop {
    margin-top: 40px;
}